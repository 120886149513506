import React from 'react'
import { TweenMax, Power1 } from 'gsap'

class Heading extends React.Component {
  componentWillAppear(callback) {
    const el = this.container
    console.log(el)
    TweenMax.from(el, 10, { opacity: 0, y: 100, ease: Power1.easeOutBack, onComplete: callback })
  }

  componentWillEnter(callback) {
    const el = this.container
    console.log(el)
    TweenMax.from(el, 10, { opacity: 0, y: 100, ease: Power1.easeOutBack, onComplete: callback })
  }

  componentWillLeave(callback) {
    const el = this.container
    TweenMax.to(el, 10, { opacity: 0, y: 100, ease: Power1.easeOut, onComplete: callback })
  }

  render() {
    return (
      <h1 className="page-heading">{ this.props.text }</h1>
    )
  }
}

export default Heading
