import React from 'react'
import Layout from '../components/layout'
import { Row, Col } from 'react-flexbox-grid'
import Heading from '../components/heading'

export default () => (
  <Layout>
    <div>
      <Row className="heading" middle="xs" center="xs">
        <Col xs={10}>
          <Heading text="OOPS!" />
        </Col>
        <Col xs={10}>
          <p>You just hit a route that doesn't exist... the sadness.</p>
        </Col>
      </Row>
    </div>
  </Layout>
)
